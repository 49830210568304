@import '~@amzn/awsui-design-tokens/polaris.scss';

.awsui {
  header {
    background-color: $color-background-home-header;
    color: $color-text-home-header-default;
    position: fixed;
    width: 100%;
    z-index: 1;
    nav {
      height: 60px;
      padding-top: 0.5em;
      z-index: 1500;
      align-items: center;

      .header-search {
        padding-right: 15px;
        width: 250px;
        float: right;
      }

      .header-title-container {
        cursor: pointer;
        // width: 165px;
        // height: 35px;
        margin: 0;
        padding: 0 0 0 1em;
        box-sizing: border-box;
        position: relative;

        .header-logo-item {
          color: $color-text-home-header-secondary;
          width: 135px;
          display: inline-block;
          position: relative;

          &__content {
            // padding: 0 16px;

            .awsui-icon {
              padding-right: 10px;
              padding: 0;
              margin: 0;
              img {
                // width: 3.8rem;
                // height: 3.8rem;
                height: 32.725px;
              }
            }
            // width: 4.0rem;
            // margin-right: 5px;

            .full-logo {
              img {
                width: 135px !important;
              }
            }

            .small-logo {
              .awsui-icon {
                img {
                  width: 65px;
                }
              }
            }

            // &__title {
            //   font-family: "Helvetica Neue";
            //   font-weight: 300;
            //   font-size: 1.8rem;
            //   letter-spacing: 1.2px;
            //   position: absolute;
            //   top: 2px;
            // }
          }
        }
      }
    }
  }
}
