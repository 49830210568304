@import '~@amzn/awsui-design-tokens/polaris.scss';

.awsui {
  .generic-not-found {
    background-image: url('../../media/images/404-background.png');
    background-size: cover;
    background-position: center top;
    background-repeat: repeat-y;
    margin: 0 auto;
    padding: 0;
    // font-size: 1.8rem;
    // height: calc(0px + 100vh);
    // max-height: 

    &__container {
      margin: 0 auto;
      padding: 60px 0;
      position: relative;
    }

    &__content {
      min-height: 2px;
      padding-top: 10px;
      position: relative;
      font-weight: lighter;
      ul {
        font-size: 1.5rem;
        text-align: center;
        display: inline-block;
        li {
          text-align: left;
          // list-style-type : none;
        }
      }
    }

    &__header {
      position: relative;
      display: inline-block;

      h1 {
        color: $color-background-home-header;
        font-size: 10rem;
      }
    }
  }
}