@import '~@amzn/awsui-design-tokens/polaris.scss';

.awsui {
  .custom-home {
    &__header {
      background-color: $color-background-home-header;
      color: $color-text-home-header-default;
      padding-bottom: 3rem;
    }
    
    &__category,
    &__header-title,
    &__header-title p {
      color: #ffffff;
      font-weight: lighter;
    }
    
    &__header-title p {
      color: #d5dbdb;
    }

    &__header-title > * {
      max-width: 70rem;
    }
    
    &__sidebar {
        margin-top: -75px;
    }

    &-image {
      img {
        width: 100%;
        height: 100%;
        border-style: none;
      }
    }
  }
}