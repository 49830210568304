@import '~@amzn/awsui-design-tokens/polaris.scss';

.awsui {
  footer {
    background-color: $color-background-home-header;
    color: $color-text-home-header-default;
    padding: 0 20px;
    text-align: left;
    line-height: 30px;
    min-height: 30px;
    position: fixed;
    bottom: 0;
    width: 100%;

    #copyright {
      color: $color-text-interactive-disabled;
      text-align: left;

      // text-align: right;

    }

    awsui-icon {
      margin-right: .7em;
    }

    a {
      color: $color-text-home-header-default;
      min-width: 50px;
      text-align: center;
      text-decoration: none;
      padding: 0 1em 0 0;
      margin-right: 1em;

      span {
        margin-right: 0.5em;
      }

      &:not(:last-child) {
        border-right: 1px solid $color-border-button-normal-default;
      }
    }
  }
}